import * as React from 'react';
import conferencePaperImage from '../../Assets/Images/Paper-standard-image.png';
import { Link } from 'react-router-dom';

import './searchResultConferencePaper.css';

export const SearchResultConferencePaper = props => (
  <Link to={'/home/conferencePaperDetails/' + props.searchResultItem.id + '?search=' + props.searchTerm} 
    key={props.index} 
    className="SearchResultItemSection" 
    onClick={props.onSearchResultClick}>
      <div className="SearchResultItemImageContainer">
        <img src={props.img || conferencePaperImage} className="img-fluid SearchResultItemImage" alt="Search Result Item" />
      </div>
      <div className="SearchResultItemDetailsContainer">
        <div className="SearchResultItemTitleContainer" id="SearchResultItemTitleContainer">{props.searchResultItem.title}</div>
        <div className="SearchResultItemAuthorContainer" id="SearchResultItemAuthorContainer">{props.searchResultItem.coPresenterNames ? props.searchResultItem.fullName + ', ' + props.searchResultItem.coPresenterNames : props.searchResultItem.fullName}</div>
        <div className="SearchResultItemNumReviewsContainer" id="SearchResultItemNumReviewsContainer">{props.searchResultItem.extraDesc1} </div>
        <div className="SearchResultItemPublicationDateContainer" id="SearchResultItemPublicationDateContainer">{props.searchResultItem.extraDesc2}</div>
        <div className="SearchResultItemNumReviewsContainer" id="SearchResultItemNumReviewsContainer">{props.searchResultItem.meetingLocation}</div>
      </div>
  </Link>
);