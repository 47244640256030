import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import environment from "../../Environments/environments";
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import './accountManagement.css'

export class AccountManagement extends React.Component {
    constructor(props) {
        super(props)

        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            accounts: [],
            loading: false
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer')
        footer.style.position = 'relative'

        this.setState({loading: true}, () => {
            this.loadDataFromServer()
        })
    }

    loadDataFromServer() {
        const url = environment.baseUrl + '/api/accounts/enumerate'

        axios
            .get(url, this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return;
                }

                this.setState({
                    accounts: response.data.accounts,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error loading account data: ', error, error.response);
                alert('Error Loading Account Data');

                this.setState({
                    accounts: [],
                    loading: false
                })
            })
    }

    editAccount = (e, id) => {
        e.preventDefault();
        this.props.history.push('/admin/accountDetail/' + id)
    }

    getAccounts() {
        if (this.state.accounts.length === 0) {
            return (
                <Fragment>
                    No Accounts Found to List
                </Fragment>
            )
        }

        return (
            <Fragment>
                {this.state.accounts.map((account, index) => {
                    return (
                        <div className="account-item" key={index}>
                            <a onClick={(e) => this.editAccount(e, account.id)}>{account.name}</a>
                        </div>
                    )
                })}
            </Fragment>
        )
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="account-manager">
                        <div className="BackgroundImageSection">
                            <div className="TextContainer container">
                                <div className="title-section col-xs-11 text-center">
                                    <div className="col-xs-12">
                                        <div className="account-text-container">
                                            <span className="account-text">Account Management</span>
                                        </div>

                                        <div>
                                            <Link to={`/admin/accountDetail/new`}>Add Account</Link>
                                        </div>

                                        {this.getAccounts()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
