import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import ScrollToTopRoute from "./ScrollToTopRoute";
import ReactGA from "react-ga4";

import * as barrel from '../Components/componentsBarrel'
import * as containers from "../Containers/containersBarrel";

const logPageView = () => {
  ReactGA.initialize("G-KBFQ4TKEHB");
  // try {
  //   setTimeout(_ => {
  //     const ga4react = new ReactGA("G-KBFQ4TKEHB");
  //     ga4react.initialize().catch(err => console.error(err));
  //   }, 4000);
  // } catch (err) {
  //       console.error(err);
  // }
  return null;
};

const Routes = () => (
  <Router>
    <div>
      <Route path="/home" component={logPageView} />

      <Route path="/home" component={barrel.Header}/>
      <Route path="/help" component={barrel.Header} />

      <Route path="/admin" component={barrel.AdminHeader}/>

      <Switch>
        <Route path="/help" component={barrel.Help} />
        <ScrollToTopRoute path="/home/login" component={containers.Login} />
        <ScrollToTopRoute path="/home/about" component={barrel.About} />
        <ScrollToTopRoute path="/home/volunteer/:Id" component={barrel.Volunteer} />
        <ScrollToTopRoute path="/home/sponsors" component={barrel.Sponsors} />
        <ScrollToTopRoute path="/home/contact" component={barrel.Contact} />
        <ScrollToTopRoute path="/home/rbl" component={barrel.RBL} />
        <ScrollToTopRoute path="/home/publishers" component={barrel.Publishers} />
        <ScrollToTopRoute path="/home/volunteerConfirmation" component={barrel.VolunteerConfirmation} />
        <ScrollToTopRoute path="/home/notifications" component={containers.BookNotificationsLandingPage} />
        <ScrollToTopRoute path="/home/journalNotifications" component={containers.JournalNotificationsLandingPage} />

        <Route path="/home/conferencePaperDetails/:Id" component={barrel.ConferencePaperDetails} />
        <Route path="/home/bookDetails/:Id" component={containers.BookDetailsContainer} />

        <Route
            path="/home/searchResults/:searchTerm"
            perPage={3}
            component={containers.SearchResultsLandingPage}
        />

        <ScrollToTopRoute path="/admin" exact component={barrel.Admin} />

        <ScrollToTopRoute path="/admin/reports" component={barrel.Reports} />
        <ScrollToTopRoute path="/admin/report/importSummaryReport" component={barrel.ImportSummaryReport} />

        <ScrollToTopRoute path="/admin/system" component={barrel.SystemMaintenance} />
        {/* <ScrollToTopRoute path="/admin/users" component={barrel.AdminUsers} /> */}
        <ScrollToTopRoute path="/admin/users" component={barrel.User} />
        <ScrollToTopRoute path="/admin/accountManagement" component={barrel.AccountManagement} />
        <ScrollToTopRoute path="/admin/accountingExport" component={barrel.AccountingExport} />
        <ScrollToTopRoute path="/admin/notificationsSnapshot" component={barrel.NotificationsSnapshotReport} />
        <ScrollToTopRoute path="/admin/subjectSelectionsSnapshot" component={barrel.SubjectSelectionsSnapshotReport} />
        <ScrollToTopRoute path="/admin/journalSelectionsSnapshot" component={barrel.JournalSelectionsSnapshotReport} />
        <ScrollToTopRoute path="/admin/metamgmt" component={barrel.MetadataManagement} />
        <Route path="/admin/bookDetails/:Id" component={containers.AdminBookDetailsContainer} />
        <Route path="/admin/bookEditor/:Id" component={barrel.BookEditor} />
        <Route path="/admin/accountDetail/:Id" component={barrel.AccountDetail} />
        <Route path="/admin/uploadMeta" component={barrel.UploadMeta} />
        <Route path="/admin/notificationTest" component={containers.NotificationTest} />
        <Route path="/admin/journalNotificationTest" component={containers.JournalNotificationTest} />
        <Route path="/admin/rblexport" component={barrel.RBLExport} />
        <Route path="/admin/journalsmenu" component={barrel.JournalsMenu} />
        <Route path="/admin/journals" component={barrel.Journals} />
        <Route path="/admin/journalDetail/:Id" component={barrel.JournalDetail} />
        <Route path="/admin/journalIssues" component={barrel.JournalIssues} />
        <Route path="/admin/journalIssuesDetail/:Id" component={barrel.JournalIssuesDetail} />
        <Route path="/admin/usermenu" component={barrel.UserMenu} />
        <Route path="/admin/user" component={barrel.User} />
        <Route path="/admin/userDetail/:Id" component={barrel.UserDetail} />

        <Route 
          path="/admin/searchResults/:searchTerm" 
          component={containers.AdminSearchResultsLandingPage} />

        <Route path="/home" exact component={barrel.Home}/>
        <Route path="/" exact render={() => <Redirect to="/home"/> }/>
      </Switch>

      <Route path="/home" component={barrel.Footer}/>
      <Route path="/help" component={barrel.Footer}/>
      <Route path="/admin" component={barrel.AdminFooter}/>
    </div>
  </Router>
);

export default Routes;
